<template>
  <b-card no-body>
    <b-card-header>
      <div
        class="font-medium-1 mt-50 center"
      >
        <span class="title">{{ leftTitle }}</span>
      </div>
    </b-card-header>

    <b-card-body style="margin-left: 10px; margin-right: 0px;">
      <b-row class="align-content-center justify-content-center h-100">
        <b-col
          v-for="(item, index) in barSeriesArr"
          :key="index"
        >
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="32"
                :variant="backgrounds[index]"
              >
                <feather-icon
                  size="16"
                  icon="CheckIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                <span class="info-title">{{ item }}</span>
              </h5>
              <b-card-text class="mb-0">
                {{ categories[index] }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    leftTitle: String,
    rightTitle: String,
    circleLabel: String,
    categories: Array,
    barSeriesArr: Array,
    circleSeries: Array,
    colors: {
      type: Array,
      default: () => ['#7039c5', '#2b9bf4', '#FDAC34', '#ea5455', '#28c76f'],
    },
    backgrounds: {
      type: Array,
      default: () => ['light-primary', 'light-primary', 'light-info', 'light-warning', 'light-danger', 'light-success'],
    },
  },
  data() {
    return {
      barSeries: [{
        data: this.barSeriesArr,
      }],
      chartOptions: {
        chart: {
          height: 200,
          type: 'bar',
          stacked: false,
          events: {
            click(chart, w, e) {
              // console.log(chart, w, e)
            },
          },
          toolbar: {
            show: false,
          },

        },
        tooltip: {
          enabled: false,
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            columnWidth: '30%',
            barHeight: '100%',
            distributed: true,
            dataLabels: {
              position: 'center',
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              colors: this.colors,
              fontSize: '12px',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
      },
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 160,
              offsetY: 0,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '70%',
              },
              track: {
                // background: '#ffffff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [this.circleLabel],
        },
      },
      goalOverviewRadialBar: {
        chart: {
          height: 100,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ['#20E647'],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '70%',
              background: '#482C50',
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: '#ffffff',
                fontSize: '12px',
              },
              value: {
                offsetY: 5,
                color: '#ffffff',
                fontSize: '20px',
                show: true,
              },
              total: {
                show: true,
                label: '完成度',
                color: '#ffffff',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#87D4F9'],
            inverseColors: true,
            opacityFrom: 0,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        labels: ['完成度'],
      },
      goalOverview: {
        processing: 0,
        finished: 0,
        series: this.circleSeries,
      },
    }
  },

}
</script>

<style scoped>
/*info-title*/
.info-title {
  width: 60px;
  text-align: center;
}
</style>
