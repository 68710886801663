<template>
  <el-card class="mb10">
    <div class="title">风险统计</div>
    <div class="risk-statistics">
      <div class="total">
        <vue-apex-charts type="pie"
                         height="140"
                         class="mt-0 mb-0"
                         :options="riskTotalOptions"
                         :series="riskTotalSeries" />
        <p>风险总数</p>
      </div>
      <div v-if="isRectify"
           class="total">
        <vue-apex-charts type="pie"
                         height="140"
                         class="mt-0 mb-0"
                         :options="riskRectifyOptions"
                         :series="riskRectifySeries" />
        <p>整改状态占比</p>
      </div>
      <div v-else
           class="total">
        <vue-apex-charts type="pie"
                         height="140"
                         class="mt-0 mb-0"
                         :options="riskWorkOptions"
                         :series="riskWorkSeries" />
        <p>风险业务占比</p>
      </div>
      <div class="total">
        <vue-apex-charts type="pie"
                         height="140"
                         class="mt-0 mb-0"
                         :options="riskSouceOptions"
                         :series="riskSouceSeries" />
        <p>风险来源占比</p>
      </div>
      <div class="total">
        <vue-apex-charts type="pie"
                         height="140"
                         class="mt-0 mb-0"
                         :options="riskLevelOptions"
                         :series="riskLevelSeries" />
        <p>风险等级占比</p>
      </div>
    </div>
  </el-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts,
  },
  props: {
    isRectify: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: false,
    },
    levels: {
      type: Array,
      default: []
    },
    products: {
      type: Array,
      default: []
    },
    sources: {
      type: Array,
      default: []
    },
  },
  data () {
    return {
      riskTotalSeries: [55],
      riskRectifySeries: [3, 3, 3],
      riskWorkSeries: [34, 21],
      riskSouceSeries: [20, 24, 11],
      riskLevelSeries: [0, 6, 14, 21, 14],
      riskTotalOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false
        },
        labels: ['风险总数'],
        dataLabels: {
          enabled: true,
          formatter: function (value, { w }) {
            return w.config.series[0]
          },
          style: {
            fontSize: '30px',
            fontWeight: '400',
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -27
            }
          }
        },
        stroke: {
          width: 0,
        },
        colors: ['#7367f0'],
      },
      riskRectifyOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false
        },
        labels: ['待处理', '整改中', '已完成'],
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -15
            }
          }
        },
        stroke: {
          width: 1,
        },
        colors: ['#ea5455', '#ff9f43', '#28c711'],
      },
      riskWorkOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false
        },
        labels: ['业务系统', '整体机构'],
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -15
            }
          }
        },
        stroke: {
          width: 1,
        },
        colors: ['#39bfff', '#39e286'],
      },
      riskSouceOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false
        },
        labels: ['快速评估', '认证评估', 'PIA评估'],
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -15
            }
          }
        },
        stroke: {
          width: 1,
        },
        colors: ['#3f7bff', '#0d40b2', '#0050ff'],
      },
      riskLevelOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false
        },
        labels: ['极低', '低', '中', '高', '严重'],
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -15
            }
          }
        },
        stroke: {
          width: 1,
        },
        colors: ['#28c711', '#28c76f', '#ffe0c2', '#ff9f43', '#ea5455',],
      },
    }
  },

}
</script>

<style lang="scss" scoped>
.title {
  border-left: solid 6px #0d40b2;
  padding-left: 8px;
  font-size: 14px;
}
.risk-statistics {
  display: flex;
  justify-content: space-evenly;
}
.total {
  width: 115px;
  text-align: center;
  font-size: 14px;
}
</style>