<template>
    <el-tooltip
            placement="top"
            effect="dark">
        <div slot="content">{{text}}</div>
        <div  class='myNote'>{{text}}</div>
    </el-tooltip>
</template>

<script>
  export default {
    props: {
      text: {
        type: String
      }
    },
    data() {
      return {
        showTooltip: true
      }
    },
    methods: {
      showTips(obj){
        /*obj为鼠标移入时的事件对象*/

        /*width为文本在页面中所占的宽度，创建标签，加入到页面，获取width,最后在移除*/
        let TemporaryTag = document.createElement('span');
        TemporaryTag.innerText = this.text;
        TemporaryTag.className = 'getTextWidth';
        document.querySelector('body').appendChild(TemporaryTag);
        let currentWidth = document.querySelector('.getTextWidth').offsetWidth;
        document.querySelector('.getTextWidth').remove();

        /*cellWidth为表格容器的宽度*/
        const cellWidth = obj.target.offsetWidth
        /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
        currentWidth <= (2*cellWidth) ? this.showTooltip = false : this.showTooltip = true
      }
    }
  }
</script>

<style scoped>
    .myNote{
        display:-webkit-box;
        text-overflow:ellipsis;
        overflow:hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient:vertical;
    }
</style>
