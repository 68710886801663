import { get, post } from '@/libs/axios'

//风险公有接口

//删除风险
export const DeleteRiskManageByIds = params => post('/risk/manage/DeleteRiskManageByIds', params)
//获取风险详情
export const GetRiskManageDetail = params => post('/risk/manage/GetRiskManageDetail', params)
//获取风险对象名列表筛选
export const GetAllRiskObjects = () => get('/risk/manage/GetAllRiskObjects')
//获取风险来源名列表筛选
export const GetAllRiskSources = () => get('/risk/manage/GetAllRiskSources')
//获取风险类别名列表筛选
export const GetAllRiskTypes = () => get('/risk/manage/GetAllRiskTypes')

//风险态势接口

//按月份获取风险总量趋势
export const GetRiskCountByMouth = params => post('/risk/situation/GetRiskCountByMouth', params)
//获取风险统计数据
export const GetRiskSatisfaction = () => get('/risk/situation/GetRiskSatisfaction')
//获取风险对象
export const GetRiskSatisfactionByObject = () => get('/risk/situation/GetRiskSatisfactionByObject')
//获取风险业务
export const GetRiskSatisfactionByProduct = () => get('/risk/situation/GetRiskSatisfactionByProduct')
//获取风险业务
export const GetRiskSatisfactionByProducts = () => get('/risk/situation/GetRiskSatisfactionByProducts')
//获取风险等级
export const GetRiskSatisfactionByRiskLevel = () => get('/risk/situation/GetRiskSatisfactionByRiskLevel')
//获取风险状态
export const GetRiskSatisfactionByRiskStatus = () => get('/risk/situation/GetRiskSatisfactionByRiskStatus')
//获取风险来源
export const GetRiskSatisfactionBySources = () => get('/risk/situation/GetRiskSatisfactionBySources')
//获取风险类别
export const GetRiskSatisfactionByTypes = () => get('/risk/situation/GetRiskSatisfactionByTypes')

//风险感知接口

//指派风险
export const AssignRisk = params => post('/risk/esthesia/AssignRisk', params)
//关闭风险
export const CloseRisk = params => post('/risk/esthesia/CloseRisk', params)
//分页查询风险感知
export const GetRiskEsthesiaListByPage = params => post('/risk/esthesia/GetRiskEsthesiaListByPage', params)
export const RiskPage = (queryType,params) => post(`/risk/${queryType}/page`, params)

//手工录入风险
export const saveOrUpdateRisk = params => {
  const submitUrl = params.id ? '/risk/esthesia/UpdateRiskManage' : '/risk/esthesia/CreateRiskManage'
  return post(submitUrl, params)
}
//风险等级占比
export const GetEsthesiaSatisfactionByRiskLevel = () => get('/risk/esthesia/GetEsthesiaSatisfactionByRiskLevel')
//业务分组占比
export const GetEsthesiaSatisfactionByProducts = () => get('/risk/esthesia/GetEsthesiaSatisfactionByProducts')
//来源分组级占比
export const GetEsthesiaSatisfactionBySources = () => get('/risk/esthesia/GetEsthesiaSatisfactionBySources')
//获取顶部风险数量显示统计
export const GetEsthesiaRiskCountSatisfaction = () => get('/risk/esthesia/GetEsthesiaRiskCountSatisfaction')

//风险整改接口

//确认风险
export const ConfirmedRisk = params => post('/risk/disposal/ConfirmedRisk', params)
//处置风险
export const DisposalRisk = params => post('/risk/disposal/DisposalRisk', params)
//驳回风险
export const OverruleRisk = params => post('/risk/disposal/OverruleRisk', params)
//分页查询风险感知
export const GetRiskDisposalListByPage = params => post('/risk/disposal/GetRiskDisposalListByPage', params)
//风险等级占比
export const GetDisposalSatisfactionByRiskLevel = () => get('/risk/disposal/GetDisposalSatisfactionByRiskLevel')
//业务分组占比
export const GetDisposalSatisfactionByProducts = () => get('/risk/disposal/GetDisposalSatisfactionByProducts')
//整改状态占比
export const GetDisposalSatisfactionByRiskStatus = () => get('/risk/disposal/GetDisposalSatisfactionByRiskStatus')
//来源分组级占比
export const GetDisposalSatisfactionBySources = () => get('/risk/disposal/GetDisposalSatisfactionBySources')
//获取顶部风险数量显示统计
export const GetDisposalRiskCountSatisfaction = () => get('/risk/disposal/GetDisposalRiskCountSatisfaction')

//风险历史接口

//分页查询风险感知
export const GetRiskClearListByPage = params => post('/risk/clear/GetRiskClearListByPage', params)
//风险等级占比
export const GetClearSatisfactionByRiskLevel = () => get('/risk/clear/GetClearSatisfactionByRiskLevel')
//业务分组占比
export const GetClearSatisfactionByProducts = () => get('/risk/clear/GetClearSatisfactionByProducts')
//来源分组级占比
export const GetClearSatisfactionBySources = () => get('/risk/clear/GeClearSatisfactionBySources')
//获取顶部风险数量显示统计
export const GetClearRiskCountSatisfaction = () => get('/risk/clear/GetClearRiskCountSatisfaction')

// 风险流程图
export const GetPrivateFlowChart = params => post('/risk/situation/GetPrivateFlowChart', params)
